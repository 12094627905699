import { ToastContainer, Flip } from 'react-toastify'

/**
 * 何かしらのお知らせをする。コンポーネントとして使うことはない。
 */
const Snackbar = (): JSX.Element => (
  <ToastContainer position="top-left" theme="colored" transition={Flip} />
)

export default Snackbar
