import React, { useMemo } from 'react'
import Link, { LinkProps } from 'next/link'

export type ButtonProps = {
  /**
   * ボタンの形
   */
  variant?: 'text' | 'contained' | 'outlined'
  /**
   * disabled属性
   */
  disabled?: boolean
  /**
   * type属性
   */
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type']
  /**
   * next/linkのhref
   */
  href?: LinkProps['href']
  /**
   * next/linkのshallow
   */
  shallow?: LinkProps['shallow']
  /**
   * next/linkのreplace
   */
  replace?: LinkProps['replace']
  /**
   * clickイベント
   */
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  /**
   * clickイベント(passive:false)
   */
  onClickCapture?: React.MouseEventHandler<HTMLButtonElement>
  className?: string
  children: React.ReactNode
}

/**
 * ボタン/リンク
 *
 * hrefを指定した場合aタグとしてレンダリングされます
 */
const Button = ({
  variant = 'text',
  type = 'button',
  className = '',
  children,
  disabled = false,
  href,
  shallow,
  replace,
  onClickCapture,
  onClick,
}: ButtonProps): JSX.Element => {
  /**
   * variantによって形を変える
   */
  const classname = useMemo(() => {
    switch (variant) {
      case 'text': {
        return `w-max text-sm text-primary hover:underline disabled:hover:no-underline disabled:cursor-default ${className}`
      }
      case 'contained': {
        return `w-max inline-block bg-primary text-white font-bold text-sm min-w-[72px] h-9 leading-[36px] text-center px-4 rounded-sm hover:shadow transition-shadow disabled:bg-btn-contained-disabled disabled:cursor-default disabled:hover:shadow-none ${className}`
      }
      case 'outlined': {
        return `w-max inline-block transition-colors bg-white hover:bg-btn-outlined-hover border border-primary text-primary font-bold text-sm min-w-[72px] h-9 leading-[36px] text-center px-4 rounded-sm disabled:text-btn-outlined-disabled disabled:border-btn-outlined-disabled disabled:hover:bg-white disabled:cursor-default ${className}`
      }
      default: {
        const n: never = variant
        throw Error(`${n}は不正な値です`)
      }
    }
  }, [variant, className])

  const elementMemo = useMemo(
    () =>
      !href ? (
        <button
          type={type}
          className={classname}
          disabled={disabled}
          onClick={onClick}
          onClickCapture={onClickCapture}
        >
          {children}
        </button>
      ) : (
        <Link href={href} shallow={shallow} replace={replace}>
          <a className={classname}>{children}</a>
        </Link>
      ),
    [
      classname,
      type,
      disabled,
      onClick,
      onClickCapture,
      children,
      href,
      shallow,
      replace,
    ]
  )

  return elementMemo
}

export default Button
