import React, { useEffect } from 'react'
import { useRouter } from 'next/router'
import { FallbackProps } from 'react-error-boundary'
import { useEffectOnce } from 'react-use'
import { useQueryClient } from 'react-query'
import { Button } from 'components/atoms'
import { ErrorView } from 'components/organisms'
import { LogiAxiosError } from 'utilitys/query'

/**
 * errorがAxiosErrorかどうかを判定する
 */
const isAxiosError = (error: Error): error is LogiAxiosError =>
  !!(error as LogiAxiosError).isAxiosError

/**
 * error処理を行うコンポーネント
 */
const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  /*
   * クエリのキャッシュを全削除
   */
  const queryClient = useQueryClient()
  useEffectOnce(() => {
    queryClient
      .cancelQueries()
      .then(() => queryClient.invalidateQueries({ refetchActive: false }))
  })

  const { replace, events, pathname } = useRouter()
  /*
   * 別のページへ遷移完了後にErrorBoundaryの状態を初期化する
   */
  useEffect(() => {
    events.on('routeChangeComplete', resetErrorBoundary)

    return () => events.off('routeChangeComplete', resetErrorBoundary)
  }, [events, resetErrorBoundary])

  /*
   * apiリクエスト以外のエラー
   */
  if (!isAxiosError(error))
    return (
      <ErrorView title={error.message}>
        <p>システム管理者に連絡して下さい。</p>
      </ErrorView>
    )

  /*
   * レスポンスが無い時。通信エラーなど。
   */
  if (!error.response)
    return (
      <ErrorView title={error.message}>
        <p>システム管理者に連絡して下さい。</p>
      </ErrorView>
    )

  /*
   * ステータスコードによって表示内容を分ける
   */
  switch (error.response.status) {
    case 401:
    case 419: {
      const signinUrl = '/signin'
      if (pathname === signinUrl) {
        replace(signinUrl)
      } else {
        replace({ pathname: signinUrl, query: { url: pathname } })
      }

      return null
    }
    case 403: {
      return (
        <ErrorView title="403 | 権限がありません">
          <Button href="/" replace>
            ホーム
          </Button>
        </ErrorView>
      )
    }
    case 404: {
      return <ErrorView title="404 | ページが見つかりません" />
    }
    case 500: {
      return (
        <ErrorView title="500 | サーバーエラー">
          {error.response.data?.message && (
            <h2 className="text-error">{error.response.data.message}</h2>
          )}
          <p>時間をおいて再度お試しください。</p>
          <Button variant="contained" onClick={resetErrorBoundary}>
            再度ページを表示
          </Button>
          <Button href="/" replace>
            ホーム
          </Button>
        </ErrorView>
      )
    }
    default: {
      return (
        <ErrorView
          title={
            error.response.data?.message
              ? `${error.response.status} | ${error.response.data.message}`
              : error.message
          }
        />
      )
    }
  }
}

export default ErrorFallback
