import React, { createContext, useContext, useReducer, useEffect } from 'react'
import { useRouter } from 'next/router'

type State = {
  isSpSidebarShow: boolean
  isPcSidebarWide: boolean
  isSettingsOpen: boolean
  isHiddenForPrint: boolean
}

const initialState: State = {
  isSpSidebarShow: false,
  isPcSidebarWide: true,
  isSettingsOpen: false,
  isHiddenForPrint: false,
}

type Action =
  | { type: 'TOGGLE_SP_SIDEBAR' }
  | { type: 'CLOSE_SP_SIDEBAR' }
  | { type: 'TOGGLE_PC_SIDEBAR' }
  | { type: 'TOGGLE_SETTINGS' }
  | { type: 'TOGGLE_PRINT' }

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'TOGGLE_SP_SIDEBAR': {
      return { ...state, isSpSidebarShow: !state.isSpSidebarShow }
    }
    case 'CLOSE_SP_SIDEBAR': {
      return { ...state, isSpSidebarShow: false }
    }
    case 'TOGGLE_PC_SIDEBAR': {
      return { ...state, isPcSidebarWide: !state.isPcSidebarWide }
    }
    case 'TOGGLE_SETTINGS': {
      return { ...state, isSettingsOpen: !state.isSettingsOpen }
    }
    case 'TOGGLE_PRINT': {
      return { ...state, isHiddenForPrint: !state.isHiddenForPrint }
    }
    default: {
      const _: never = action
      throw Error('action.typeが不正です')
    }
  }
}

type LayoutContext = {
  state: State
  dispatch: React.Dispatch<Action>
}

const layoutContext = createContext<LayoutContext>({
  state: initialState,
  dispatch: () => undefined,
})

type Props = {
  children: React.ReactNode
}

export const LayoutProvider = ({ children }: Props): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const { events } = useRouter()
  useEffect(() => {
    const closeSpSidebar = () => dispatch({ type: 'CLOSE_SP_SIDEBAR' })
    events.on('routeChangeComplete', closeSpSidebar)

    return () => {
      events.off('routeChangeComplete', closeSpSidebar)
    }
  }, [events])

  return (
    <layoutContext.Provider value={{ state, dispatch }}>
      {children}
    </layoutContext.Provider>
  )
}

export const useLayoutContext = (): LayoutContext => useContext(layoutContext)
