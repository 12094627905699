import React from 'react'
import { useRouter } from 'next/router'
import { Button } from 'components/atoms'

type Props = {
  /**
   * タイトル
   */
  title: string
  children?: React.ReactNode
}

/**
 * エラーページのラッパー
 */
const ErrorView = ({ title, children }: Props): JSX.Element => {
  const { back } = useRouter()

  return (
    <main className="grid min-h-screen grid-rows-[1fr,auto]">
      <section className="flex flex-col items-center space-y-6 self-center">
        <svg
          className="inline-block"
          width="32"
          height="27"
          viewBox="0 0 32 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.416748 26.9167H31.5834L16.0001 0L0.416748 26.9167ZM17.4167 22.6667H14.5834V19.8333H17.4167V22.6667ZM17.4167 17H14.5834V11.3333H17.4167V17Z"
            fill="#A1A6AC"
          />
        </svg>
        <h1 className="text-center text-2xl font-bold text-form">{title}</h1>
        {children}
        <Button onClick={back}>前のページへ戻る</Button>
      </section>
      <p className="p-3.5 text-center text-form md:text-right">
        Powered by Karabiner Inc.
      </p>
    </main>
  )
}

export default ErrorView
