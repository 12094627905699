import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import * as Sentry from '@sentry/nextjs'
import ErrorFallback from './WithErrorBoundary.ErrorFallback'

type Props = { children: React.ReactNode }

const onError = (err: Error): void => {
  Sentry.captureException(err)
}

/**
 * 本プロジェクト唯一無二のErrorboundary
 */
const WithErrorBoundary = ({ children }: Props): JSX.Element => (
  <ErrorBoundary FallbackComponent={ErrorFallback} onError={onError}>
    {children}
  </ErrorBoundary>
)

export default WithErrorBoundary
