/**
 * 開豁環境か否か
 */
export const IS_DEVELOPMENT = process.env.NODE_ENV === 'development'

/**
 * 開豁環境か否か
 */
export const IS_STAGEING = process.env.NEXT_PUBLIC_STAGEING === 'true'

/**
 * テスト環境(jest/storybook)か否か
 */
export const IS_TEST =
  process.env.NODE_ENV === 'test' || process.env.STORYBOOK === 'true'

/**
 * next/dynamicの第2引数に渡す値。テスト環境か否かによって値が変わる
 */
export const DYNAMIC_OPTION = IS_TEST ? { ssr: false } : { suspense: true }
// export const DYNAMIC_OPTION = { ssr: false }

/**
 * apiサーバーのurl https://~~~
 */
export const BACKEND_API_URL = process.env.NEXT_PUBLIC_BACKEND_API_URL

/**
 * 印刷関連のapiをモックするか否か
 */
export const SHOULD_MOCK_PRINT_API =
  process.env.NEXT_PUBLIC_API_MOCKING === 'true'
