import React from 'react'
import type { AppProps } from 'next/app'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { WithErrorBoundary } from 'components/compositions'
import { Snackbar } from 'components/atoms'
import 'tailwindcss/tailwind.css'
import 'react-toastify/dist/ReactToastify.css'
import 'components/atoms/Snackbar/Snackbar.css'
import 'components/atoms/DatePicker/DatePicker.css'
import { LayoutProvider } from 'components/organisms'
import { SHOULD_MOCK_PRINT_API, IS_DEVELOPMENT } from 'settings'

if (SHOULD_MOCK_PRINT_API && typeof window === 'object') {
  import('mock/msw-comfig').then(({ newMswWorker }) => {
    const { worker } = newMswWorker()
    worker.start({ onUnhandledRequest: 'bypass', quiet: true })
  })
}

const queries = {
  suspense: true,
}

const queryClient = new QueryClient(
  IS_DEVELOPMENT
    ? {
        defaultOptions: {
          queries: {
            retry: 0,
            refetchOnWindowFocus: false,
            ...queries,
          },
          mutations: { retry: 0 },
        },
      }
    : { defaultOptions: { queries } }
)

function MyApp({ Component, pageProps }: AppProps): React.ReactNode {
  return (
    <QueryClientProvider client={queryClient}>
      <WithErrorBoundary>
        <LayoutProvider>
          <Component {...pageProps} />
        </LayoutProvider>
      </WithErrorBoundary>
      <Snackbar />
      <ReactQueryDevtools position="top-right" />
    </QueryClientProvider>
  )
}
export default MyApp
